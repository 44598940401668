import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import PlatformFeaturesMolecule from '../components/molecules/PlatformFeatures';
import BenefitSection from '../components/organisms/BenefitSection';
import CaseStudySection from '../components/organisms/CaseStudySection';
import PlatformFeatures from '../components/organisms/PlatformFeatures';
import ProductPageHero from '../components/organisms/ProductPageHero';

const DynamicallyUseLiveDataPage = ({ data: { page: data } }) => (
  <Layout>
    <Seo post={data} />

    <ProductPageHero
      {...data.dynamicallyUseLiveData.dynamicallyUseLiveDataHero}
    />

    <PlatformFeatures
      {...data.dynamicallyUseLiveData.dataDrivenCreativeSection}
    />
    <section className="my-16 md:my-32">
      <div className="u-container">
        <PlatformFeaturesMolecule
          features={data.dynamicallyUseLiveData.features}
        />
      </div>
    </section>

    {data.dynamicallyUseLiveData.dynamicallyUseLiveDataBenefits.length > 0 && (
      <BenefitSection
        imageSide="left"
        animationName="spirable-live-sports"
        animationBackground="linear-gradient(140.62deg, #FFD99F -35.96%, #FF7264 143.07%)"
        {...data.dynamicallyUseLiveData.dynamicallyUseLiveDataBenefits[0]}
      />
    )}

    {data.dynamicallyUseLiveData.dynamicallyUseLiveDataBenefits.length > 1 && (
      <BenefitSection
        imageSide="right"
        {...data.dynamicallyUseLiveData.dynamicallyUseLiveDataBenefits[1]}
      />
    )}
    {data.dynamicallyUseLiveData.dynamicallyUseLiveDataBenefits.length > 2 && (
      <BenefitSection
        imageSide="left"
        primaryCta
        {...data.dynamicallyUseLiveData.dynamicallyUseLiveDataBenefits[2]}
      />
    )}
    <CaseStudySection
      {...data.dynamicallyUseLiveData.caseStudy}
      backgroundImage="linear-gradient(139.75deg, #FFD99F -17.3%, #FF7264 128.15%)"
    />
  </Layout>
);

export const pageQuery = graphql`
  query DynamicallyUseLiveDataQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      ...SEO
      dynamicallyUseLiveData {
        dynamicallyUseLiveDataHero {
          title
          body
          image {
            ...Image
          }
        }
        dataDrivenCreativeSection {
          lead
          title
          body
          categories {
            name
            tiles {
              icon {
                ...Image
              }
              title
              body
            }
          }
        }
        features {
          lead
          title
          body
          image {
            ...Image
          }
        }
        dynamicallyUseLiveDataBenefits {
          lead
          title
          body
          image {
            ...Image
          }
          cta {
            label
            to
          }
        }
        caseStudy {
          logo {
            ...Image
          }
          title
          body
          cta {
            label
            to
          }
          quote
          author {
            name
            title
            photo {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default DynamicallyUseLiveDataPage;
